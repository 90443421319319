/**@Packages */
import { Badge } from "antd";
// import { Badge } from "antd";
/**@CustomImports */
import dateUtil from "../containers/UI/FormatDate/formatDate";
import CommonBadge, { BadgeColors } from "./Badge/CommonBadge";
/**@ExtractData from Object */
const getValueFromObject = (data, column) => {
    let { searchField, searchKey, searchKeyType, type, className, format, style } = column;
    /**@DateStyleForObject */
    if(searchKeyType === 'object' && type === "date" && data?.[searchKey]?.[searchField]) return <div className={"ms-3 "+ className ? className: ""} style={column.style && column.style}>{dateUtil.formatDate(data?.[searchKey]?.[searchField], format)}</div>
    if(searchKeyType === 'object' && data?.[searchKey]?.[searchField]) return <div className={"ms-3 "+ className ? className:""} style={column.style && column.style}>{data?.[searchKey]?.[searchField]}</div>
    else return hypenStyle(data, column);
}

/**@HypenStyle */
const hypenStyle = (data, column) => {
    const { className } = column;
    return <div className={`${className && className}`} style={{fontWeight:"bold", fontSize:"20px"}}>-</div>
}

/**@SNo */
const sNo = (data, primeCol, col) => {
    const { className } = col;
    return <div className={className && className }>{primeCol.rowIndex + 1 }</div>
}
/**@TextStyle */
const textStyle = (data, column) => {
    if(data?.[column.searchKey]) return <div style={column.style && column.style}>{data[column.searchKey]}</div>
}
/**@DateStyle */
const dateStyle = (data, column) => {
    const { searchKey, className, style } = column;
    if(data && data[searchKey]) return <div style={style && style} className={className && className}>{dateUtil.formatDate(data[searchKey], column?.format)}</div>
    return hypenStyle(data, column);
}

/********************* @CustomStyles Section ************************************/

/**@GenderBadge */
export const genderBadge = (data, column) => {
    const { field, className, style } = column;
    if(data[field]?.toLowerCase() == "male") return <CommonBadge customBadge color={BadgeColors.BLUE}>{data[field]}</CommonBadge>
    else if(data[field]?.toLowerCase() == "female") return <CommonBadge customBadge color={BadgeColors.PINK}>{data[field]}</CommonBadge>
    else return <CommonBadge customBadge badgeColor={BadgeColors.ORANGE}>{data[field]}</CommonBadge>

}

/**@ProcedureCodeStyle */
const procedureCode = (data, primeCol, column) => {
    console.log("V++++++++++++++++++++++++++++++++++++++++++++++++++++")
    const { searchKey, searchField,} = column;
    const codes = ["D7110", "D6710", "D2335", "D2390", "D2391", "D2392", "D3920"];
    if(data && data?.[searchKey]?.[searchField] && codes.includes(data[searchKey][searchField])) {
        primeCol.props.rowClassName = "bg-color-primary";
        primeCol.props.customClassName = "bg-color-primary";
    }
}

/**@ClassNamesStyles */
export const customClassName = (data, primeTable) =>{
    if(primeTable?.props?.type === "Procedures"){
        const codes = ["D7110", "D6710", "D2335", "D2390", "D2391", "D2392", "D3920"];
        if(data && data?.teethProcedureSteps?.treatmentStatus === "Complete") return "procedure-completed";
        else return "procedure-pending";
    }
}

/** */

/**@ColumnStyles */
const customColumnStyles = (data, primeCol, column, cus) => {
    let { searchField, searchKey, searchKeyType, type, customStyle } = column;
    if(customStyle && customStyle === "genderBadge") return genderBadge(data, column);
    if(customStyle && customStyle === "procedureCode") return procedureCode(data, primeCol, column);
    if(searchKeyType === "SNo") return sNo(data, primeCol, column);
    if(searchKeyType ==="text" && type === "date") return dateStyle(data, column);
    if(searchKeyType === "object") return getValueFromObject(data, column);
    if(searchKeyType === "text") return textStyle(data, column);
    else return <div className="text-center" style={{fontWeight:"bold"}}>-</div>
}

export default customColumnStyles;