import React, { useEffect, useRef, useState, useContext } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { Button } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import formatDate from "../../../UI/FormatDate/formatDate";
import { useParams } from "react-router";
import { GlobalContext } from "../../../App/App";
// config file
const ClockInClockOut = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const [status, setStatus] = useState('Not Clocked In');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [buttonStates, setButtonStates] = useState({
    clockIn: true,
    startBreak: false,
    endBreak: false,
    clockOut: false,
  });
  const [employees, setEmployees] = useState([]);
  const [clockInId, setClockInId] = useState(null);
  const dataTableRef = useRef(null);


  const params = useParams();
  const loginCredentials = JSON.parse(localStorage.getItem('loginCredentials'));
  const isAdmin = loginCredentials?.role === 'Admin';
  const context = useContext(GlobalContext);

  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Hospitals");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
    employeeData();
    if (!isAdmin) {
      setSelectedEmployee(loginCredentials);
    }
    checkClockInStatus();
  }, []);

  useEffect(() => {
    checkIfButtonsShouldBeDisabled();
  }, []);

  const checkIfButtonsShouldBeDisabled = () => {
    const clockOutDate = localStorage.getItem('clockOutDate');
    const today = new Date().toDateString();
    if (clockOutDate === today) {
      setButtonStates({
        clockIn: false,
        startBreak: false,
        endBreak: false,
        clockOut: false,
      });
    }
  };
  const checkClockInStatus = async () => {
    const response = await fetchMethodRequest('GET', apiCalls.clockInClockOut);
    if (response && response.clockinandclockouts && response.clockinandclockouts.length > 0) {
      const latestRecord = response.clockinandclockouts[0];
      const { clockInDateAndTime, clockOutDateAndTime, breaks, _id, employeeName } = latestRecord;

      let startBreakDateAndTime, endBreakDateAndTime;
      if (breaks && breaks.length > 0) {
        const latestBreak = breaks[breaks.length - 1];
        startBreakDateAndTime = latestBreak.startBreakDateAndTime;
        endBreakDateAndTime = latestBreak.endBreakDateAndTime;
      }

      const currentDate = new Date();
      const latestClockInDate = new Date(clockInDateAndTime);
      const isIncompletePreviousRecord = latestClockInDate.toDateString() !== currentDate.toDateString() && !clockOutDateAndTime;

      if (isIncompletePreviousRecord) {
        setStatus('You didn\'t clock out yesterday. Please contact admin to update your record.');
        setButtonStates({
          clockIn: true,
          startBreak: false,
          endBreak: false,
          clockOut: false,
        });
      } else if (clockInDateAndTime && !clockOutDateAndTime) {
        if (startBreakDateAndTime && !endBreakDateAndTime) {
          setButtonStates({
            clockIn: false,
            startBreak: false,
            endBreak: true,
            clockOut: false,
          });
          setStatus('On Break');
        } else if (endBreakDateAndTime) {
          setButtonStates({
            clockIn: false,
            startBreak: true,
            endBreak: false,
            clockOut: true,
          });
          setStatus('Clocked In');
        } else {
          setButtonStates({
            clockIn: false,
            startBreak: true,
            endBreak: false,
            clockOut: true,
          });
          setStatus('Clocked In');
        }
        setClockInId(_id);
        setSelectedEmployee(employeeName);
      } else if (clockOutDateAndTime) {
        resetState();
      }
    } else {
      resetState();
    }
  };

  const resetState = () => {
    setStatus('Not Clocked In');
    setClockInId(null);
    setButtonStates({
      clockIn: true,
      startBreak: false,
      endBreak: false,
      clockOut: false,
    });
    if (!isAdmin) {
      setSelectedEmployee(loginCredentials);
    } else {
      setSelectedEmployee(null);
    }
  };

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };
  const employeeData = () => {
    fetchMethodRequest('GET', apiCalls.users).then((res) => {
      if (res && res.users) {
        const userData = res.users;
        setEmployees(userData.map((obj) => { return { label: obj.name, value: obj._id, data: obj } }));
      }
    });
  };
  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };
  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "employeeName",
        type: "relateAutoComplete",
        placeholder: "Employee Name",
        label: "Employee Name",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Employee Name",
        derivedValue: "employeeName=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "employeeName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        searchField: "name",
        populteFields: [],
        displayFields: [],
        controllerName: "users",
        searchApi: "users",
        isMultiple: false,
        textAlign: "Center",
        show: isAdmin,
        filter: true,
        field: "employeeName",
        fieldType: "relateAutoComplete",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "date",
        type: "date",
        placeholder: "Date",
        label: "Date",
        width: 90,
        header: "Date",
        derivedValue: "date=undefined",
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateDBFormat,
        textAlign: "Center",
        show: true,
        field: "date",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "actualWorkDuration",
        type: "float",
        placeholder: "Worked Hours",
        label: "Worked Hours",
        width: 100,
        header: "Worked Hours",
        derivedValue: "actualWorkDuration=undefined",
        actions: [],
        actionsNumber: [],
        id: "actualWorkDuration",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "float",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        show: true,
        field: "actualWorkDuration",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "clockInDateAndTime",
        type: "time",
        placeholder: "Date",
        label: "Date",
        width: 90,
        header: "Clock In",
        derivedValue: "clockInDateAndTime=undefined",
        actions: [],
        actionsNumber: [],
        id: "datclockInDateAndTime",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Time",
        dateFormat: config.dateDBFormat,
        textAlign: "Center",
        show: true,
        field: "clockInDateAndTime",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "clockOutDateAndTime",
        type: "time",
        placeholder: "Clock Out",
        label: "Clock Out",
        width: 90,
        header: "Clock Out",
        derivedValue: "clockOutDateAndTime=undefined",
        actions: [],
        actionsNumber: [],
        id: "clockOutDateAndTime",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "time",
        dateFormat: config.dateDBFormat,
        textAlign: "Center",
        show: true,
        field: "clockOutDateAndTime",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "breaks",
        type: "Array",
        placeholder: "Break Times",
        label: "Break Times",
        width: 90,
        header: "Break Times",
        derivedValue: "breaks=undefined",
        actions: [],
        actionsNumber: [],
        id: "breaks",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Array",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        show: true,
        field: "breaks",
        mobile: true,
        displayInSettings: true,
        style: {
          width: '200px',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
      },
      {
        name: "breakDuration",
        type: "float",
        placeholder: "Break Duration",
        label: "Break Duration",
        width: 100,
        header: "Break Duration",
        derivedValue: "breakDuration=undefined",
        actions: [],
        actionsNumber: [],
        id: "breakDuration",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "float",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        show: true,
        field: "breakDuration",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateDBFormat,
        textAlign: "Center",
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateDBFormat,
        textAlign: "Center",
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
      },
    ];
    if (isAdmin) {
      data.push({
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
      });
    }

    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "employeeName",
        type: "relateAutoComplete",
        placeholder: "Select Employee",
        label: "Employee Name",
        width: "150px",
        // addFormOrder: 1,
        // editFormOrder: 1,
        header: "Employee Name",
        derivedValue: "employeeName=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "employeeName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        isEditDisabled: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        searchField: "name",
        fieldType: "relateAutoComplete",
        controllerName: "users",
        searchApi: apiCalls.users,
        textAlign: "Center",
        show: true,
        field: "employeeName",
        fieldForKey: 'clinicsId',
        hasDependencyField: 'clinicId',
        hasDependency: "true",
        hasDependencyFieldType: 'in',
        showOrHideFields: [],
        mobile: true,
        isEditDisabled: true,
        displayInSettings: true,
        customWidthClass: "col-6",
        providerFilter: true,
      },
      {
        name: "date",
        type: "date",
        placeholder: "Date",
        label: "Date",
        width: "130px",
        // addFormOrder: 8,
        // editFormOrder: 8,
        header: "Date",
        derivedValue: "date=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "date",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: config.dateDBFormat,
        fieldType: "Date",
        textAlign: "Center",
        isEditDisabled: true,
        show: true,
        field: "date",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        customWidthClass: "col-6",
        maxDate: new Date()
      },
      {
        name: "clockInDateAndTime",
        type: "customClockTime",
        placeholder: "Clock In",
        label: "Clock In",
        width: "120px",
        // addFormOrder: 11,
        // editFormOrder: 11,
        header: "clockIn",
        derivedValue: "clockInDateAndTime=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "clockInDateAndTime",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "clockInDateAndTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        customWidthClass: "col-6"
      },
      {
        name: "clockOutDateAndTime",
        type: "customClockTime",
        placeholder: "Clock Out",
        label: "Clock Out",
        width: "120px",
        // addFormOrder: 10,
        // editFormOrder: 10,
        header: "Clock Out",
        derivedValue: "clockOutDateAndTime=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "clockOutDateAndTime",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "clockOutDateAndTime",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        customWidthClass: "col-6",
        validateField: "clockInDateAndTime"
      },
      {
        name: "breakDuration",
        type: "customClockTime",
        placeholder: "Break Duration",
        label: "Break Duration",
        width: "120px",
        // addFormOrder: 10,
        // editFormOrder: 10,
        header: "Break Duration",
        derivedValue: "breakDuration=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "breakDuration",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "breakDuration",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        customWidthClass: "col-6",
        isDurationPicker: "true"
      },
      {
        name: "comment",
        type: "textarea",
        placeholder: "Comment",
        label: "Comment",
        derivedValue: "comment=undefined",
        capitalizeTableText: false,
        // addFormOrder: 3,
        // editFormOrder: 3,
        id: "comment",
        required: true,
        displayinaddForm: "false",
        displayineditForm: "true",
        show: true,
        mobile: true,
        displayInSettings: true,
        customWidthClass: "col-6",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
      },
    ];
  };

  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };


  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();
  };

  const disableButtonsForTheDay = () => {
    const today = new Date().toDateString();
    localStorage.setItem('clockOutDate', today);
    setButtonStates({
      clockIn: false,
      startBreak: false,
      endBreak: false,
      clockOut: false,
    });
  };
  const clockInClockOut = () => {
    const saveDataToServer = (action, type) => {
      setStatus(action);
      let url = `${apiCalls.clockInClockOut}`;
      let method;
      let currentDate = new Date();
      currentDate.setSeconds(0, 0);
      let clinicId = (context && context.clinicData) ? context.clinicData : "";
      let body = {
        employeeName: selectedEmployee,
        [type]: formatDate.formatDate(currentDate),
        date: formatDate.formatDate(currentDate),
        clinicId: clinicId
      };

      if (action === 'Clocked Out' || action === 'Break Started' || action === 'Break Ended') {
        method = "PUT";
        url = `${url}/${clockInId}`;
        body._id = clockInId;
      } else {
        method = "POST";
      }

      fetchMethodRequest(method, url, body)
        .then((response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
            if (dataTableRef.current) {
              dataTableRef.current.getDataFromServer();
            }
            if (action === 'Clocked In') {
              setClockInId(response.clockInAndClockOutId);
            } else if (action === 'Clocked Out') {
              setClockInId(null);
              resetState();
            }
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
            resetButtonStatesOnError(action);
          }
        })
        .catch((err) => {
          console.error(err);
          resetButtonStatesOnError(action);
        });

      switch (action) {
        case 'Clocked In':
          setButtonStates({
            clockIn: false,
            startBreak: true,
            endBreak: false,
            clockOut: true,
          });
          break;
        case 'Break Started':
          setButtonStates({
            clockIn: false,
            startBreak: false,
            endBreak: true,
            clockOut: false,
          });
          break;
        case 'Break Ended':
          setButtonStates({
            clockIn: false,
            startBreak: true,
            endBreak: false,
            clockOut: true,
          });
          break;
        case 'Clocked Out':
          setButtonStates({
            clockIn: true,
            startBreak: false,
            endBreak: false,
            clockOut: false,
          });
          setStatus('Clocked Out');
          break;
        default:
          break;
      }
    }

    const resetButtonStatesOnError = (action) => {
      switch (action) {
        case 'Clocked In':
          setButtonStates({
            clockIn: true,
            startBreak: false,
            endBreak: false,
            clockOut: false,
          });
          break;
        case 'Break Started':
          setButtonStates({
            clockIn: false,
            startBreak: true,
            endBreak: false,
            clockOut: true,
          });
          break;
        case 'Break Ended':
          setButtonStates({
            clockIn: false,
            startBreak: true,
            endBreak: false,
            clockOut: false,
          });
          break;
        case 'Clocked Out':
          setButtonStates({
            clockIn: true,
            startBreak: false,
            endBreak: false,
            clockOut: false,
          });
          break;
        default:
          setButtonStates({
            clockIn: true,
            startBreak: false,
            endBreak: false,
            clockOut: false,
          });
          break;
      }
    }
    return (
      <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto', backgroundColor: '#f4f4f4', borderRadius: '10px' }}>
        <div style={{ textAlign: 'center', marginBottom: '20px', padding: '20px', backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
          <h5>Current Status</h5>
          <p style={{ fontSize: '20px', fontWeight: 'bold' }}>{status}</p>
        </div>
        <div className="d-flex justify-content-around">
          <Button
            color="success"
            onClick={() => saveDataToServer('Clocked In', 'clockInDateAndTime')}
            style={{ width: '150px' }}
            disabled={!buttonStates.clockIn}
          >
            Clock In
          </Button>
          <Button
            color="primary"
            onClick={() => saveDataToServer('Break Started', 'startBreakDateAndTime')}
            style={{ width: '150px' }}
            disabled={!buttonStates.startBreak}
          >
            Start Break
          </Button>
          <Button
            color="primary"
            onClick={() => saveDataToServer('Break Ended', 'endBreakDateAndTime')}
            style={{ width: '150px' }}
            disabled={!buttonStates.endBreak}
          >
            End Break
          </Button>
          <Button
            color="danger"
            onClick={() => saveDataToServer('Clocked Out', 'clockOutDateAndTime')}
            style={{ width: '150px' }}
            disabled={!buttonStates.clockOut}
          >
            Clock Out
          </Button>
        </div>
      </div>
    );
  }
  const excludeKeysForExport = ["updatedKeys", "breaks", "_id", "__v", "active"];
  //please use / instead of - in sending date formates
  const keysToFormat = ["date-MM/DD/YYYY", "created-MM/DD/YYY", "updated-MM/DD/YYYY", "clockInDateAndTime-hh:mm A", "clockOutDateAndTime-hh:mm A", "startBreakDateAndTime-hh:mm A", "endBreakDateAndTime-hh:mm A"];
  const keysToExtract = { employeeName: 'name', createdBy: 'name', hospitalId: 'hospitalName', clinicId: 'abbrevation' };
  const keyOrder = ["date", "hospitalId", "clinicId", "employeeName", "clockInDateAndTime", "startBreakDateAndTime", "endBreakDateAndTime", "clockOutDateAndTime", "breakDuration", "workDuration", "updated", "updatedByName", "actualWorkDuration"];
  return (
    <span>
      {!isAdmin && clockInClockOut()}
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={
            isAdmin ? true : false
          }
          editRequired={
            isAdmin ? true : false
          }
          deleteRequired={
            isAdmin ? true : false
          }
          viewRequired={
            isAdmin ? true : false
          }
          exportRequired={
            isAdmin ? true : false
          }
          sample={false}
          // globalSearch={'Display Name/Email'}
          // type='Hospitals'
          // apiUrl={apiCalls.Hospitals}
          // exportRequried={true}
          printRequried={false}
          settingsRequired={isAdmin}
          filterRequired={false}
          gridRequried={isAdmin}
          exportToCsv={isAdmin}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.clockInClockOut}
          type="Clockify"
          displayName="Clockify"
          routeTo={'Clockify'}
          displayViewOfForm="screen"
          apiResponseKey={apiCalls.clockInClockOut.toLowerCase()}
          apiUrl={apiCalls.clockInClockOut.toLowerCase()}
          selectedId={params.id}
          disableRefresh={!isAdmin ? true : false}
          disableHeader={!isAdmin ? true : false}
          onRef={ref => dataTableRef.current = ref}
          customExportDataScreen={"Clockify"}
          customExportKey={"actualWorkDuration"}
          excludeKeysForExport={excludeKeysForExport}
          keysToFormat={keysToFormat}
          keysToExtract={keysToExtract}
          customUpdatedKeyScreen={"Clockify"}
          customDataTableFieldKey={"clockOutDateAndTime"}
          customClockInTimeValue={"clockInDateAndTime"}
          isDisablePaginator={!isAdmin}
          disableClearFilter={!isAdmin}
          disableSaveFilter={!isAdmin}
          disableActionDropdown={!isAdmin}
          keyOrder={keyOrder}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="clockInClockOut"
          apiUrl={apiCalls.clockInClockOut}
        />
      ) : null}
    </span>
  );
};

export default ClockInClockOut;
