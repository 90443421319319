import React, { useEffect, useState } from "react";
import DoAutoCompleteField from "../../../Form/Fields/DoAutoCompleteField";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import fetchMethodRequest from "../../../../config/service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faCheck, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import Pharmacies from "../../Pharmacies/components/Pharmacies";
import { Button } from 'primereact/button';
import DeleteRowModal from "../../CommonModals/DeleteRowModal";

export default function TreatmentPlanInputField(props) {
    let { i, item, Controller, control, getValues, setValue, errors, selectedData, setCustomFieldValidation, loginRole, heading, watch, reset } = props;
    const [formType, setFormType] = useState('');
    const [isOpenFormModal, setIsOpenFormModal] = useState(false);
    const [stateOptions, setStateOptions] = useState();
    const [selectedItemTxplan, setSelectedItemTxPlan] = useState();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [tableData, setTableData] = useState();
    // setCustomFieldValidation
    const [selectedItemForEdit, setSelectedItemForEdit] = useState(null);
    const itemTemplate = (option) => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '5px 10px'
            }}>
                <div>
                    <span>{option.heading ? option.heading : " "}</span> { }
                </div>
                <div>
                    <FontAwesomeIcon
                        icon={faEdit}
                        onClick={(e) => handleEditClick(option, e)}
                        style={{ cursor: 'pointer' }}
                    />
                    <FontAwesomeIcon
                        icon={faTrash}
                        onClick={(e) => { e.stopPropagation(); handleDeleteClick(option, e) }}
                        style={{ cursor: 'pointer', marginLeft: '10px', color: 'red' }}
                    />
                </div>
            </div>
        );
    };



    /**
     * 
     * @param {Number} i 
     * @param {Object} item 
     * @param {Function}} Controller 
     * @param {Function} control 
     * @param {Function} getValues 
     * @param {Function} setValue 
     * @param {Object} errors 
     * @returns 
     */
    let getAutoCompleteField = (i, item, Controller, control, getValues, setValue, errors) => {//Render the Multiple select field

        const onSelectValue = (value) => {
            const patientId = getValues('patientId');
            if (!patientId) {
                showToasterMessage('Please select a patient first.', 'warning');
                return;
            }
        }
        return <div>
            <Controller
                name={item.name}
                control={control}
                render={({ field, fieldState }) => (
                    <DoAutoCompleteField
                        input={field}
                        id={field.id}
                        name={field.name}
                        field={field}
                        item={{ ...item, itemTemplate }}
                        filterField={item.filterField}
                        filterValue={item.filterValue}
                        filterType={item.isNotEq}
                        multiple={item.isMultiple}
                        fieldState={fieldState}
                        errors={errors}
                        screen={props.type}
                        searchApi={item.searchApi}
                        searchField={item.searchField}
                        filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                        placeholder={item.placeholder}
                        label={item.label}
                        setValue={setValue}
                        getValues={getValues}
                        formType={props.formType}
                        onChange={onSelectValue}
                        watch={watch}
                    />)}
            />
        </div>
    }

    const addCustomValidations = (data) => {
        const { valObj, yup } = data;
        valObj['txPlanId']=customValidationFunction(yup);
        return valObj;
    }

    const customValidationFunction = (yup) => {
        return yup.lazy(value => {
            if (value !== undefined && value !== null && value !== '') {
                return yup.object().test('is-object', 'Select a valid option', function (value) {
                    if (typeof value !== 'object') {
                        throw this.createError({ message: 'Select a valid option', path: field.name });
                    }
                    return true;
                });
            } else {
                return yup.mixed().nullable(); // No validation rule if field has no value
            }
        });
    };

    const getFormFields = () => {
        const procedureStepsTableFields = [
            { field: 'code', header: 'Code', filter: true },
            { field: 'description', filter: true, header: 'Description', },
            { field: 'toothNumber', header: 'Tooth Number', editable: true, type: 'input' },
            { field: 'displayFee', header: 'Fee', },
        ]
        return [
            {
                name: "heading",
                type: "text",
                placeholder: "Treatment Plan",
                label: "Treatment Plan",
                derivedValue: "heading=undefined",
                capitalizeTableText: false,
                id: "heading",
                required: true,
                addFormOrder: 1,
                editFormOrder: 1,
                show: true,
                mobile: true,
                displayInSettings: true,
                customWidthClass: 'col-sm-6',
                customValidateName: 'Treatment Plan',
                style: {
                    color: "#0e4768",
                    cursor: "pointer",
                    textTransform: "capitalize",
                },
                isEditFormHidden: false,
            },
            {
                name: "note",
                type: "textarea",
                placeholder: "Notes",
                label: "Notes",
                derivedValue: "Notes=undefined",
                capitalizeTableText: false,
                addFormOrder: 2,
                editFormOrder: 2,
                id: "note",
                required: true,
                show: true,
                mobile: true,
                displayInSettings: true,
                customWidthClass: 'col-sm-6',
                style: {
                    color: "#0e4768",
                    cursor: "pointer",
                    textTransform: "capitalize",
                },
                isEditFormHidden: false,
            },
            {
                name: 'procedureSteps',
                type: 'tableItemsSelector',
                label: 'Procedures',
                addFormOrder: 2,
                editFormOrder: 2,
                tableFields: procedureStepsTableFields,//TableFields to display
                searchApi: apiCalls.procedures,//Api call for the Procedures
                show: true,
                hasDependency: true,
                fieldForKey: "hospitalId",
                hasDependencyField: "hospitalId",
                modal: "TreatmentPlan",
                heading: heading,
                selectedItemTxplan: selectedItemTxplan,
                formType: formType,
                onChange: (data) => {
                    setTableData(data);
                },
                isEditFormHidden: false,
            },
            {
                name: "teethProcedureSteps",
                type: "relateAutoComplete",
                placeholder: "Teeth Procedures",
                label: "Teeth Procedures",
                width: "150px",
                addFormOrder: 1,
                editFormOrder: 1,
                header: "Teeth Procedures",
                derivedValue: "teethProcedureSteps=undefined",
                capitalizeTableText: false,
                sortable: false,
                actions: [],
                actionsNumber: [],
                id: "teethProcedureSteps",
                displayinaddForm: "true",
                displayineditForm: "true",
                displayinlist: "true",
                isFieldRequired: "false",
                addFormOrder: 1,
                editFormOrder: 1,
                required: true,
                displayOptionsInActions: false,
                globalSearchField: "false",
                controllerId: 1008,
                controllerName: "patients",
                searchField: 'patientId',
                searchApi: "patients",
                isMultiple: false,
                textAlign: "Center",
                show: false,
                field: "patientId",
                formType: 'add',
                showOrHideFields: [],
                mobile: true,
                displayInSettings: true,
                isEditFormHidden: false,
            }
        ];
    };
    const addNewPharmacy = (e) => {
        e.preventDefault();
        if (getValues('patientId')) {
            setIsOpenFormModal(true);
            setCustomFieldValidation({ save: false, });
            e.preventDefault();
        } else {
            showToasterMessage('Please select a patient.', 'warning');
            return;
        }

    }

    function handleDeleteClick(option, e) {
        e.preventDefault();
        setItemToDelete(option);
        setIsDeleteModalOpen(true);
    };
    const handleDelete = () => {
        if (itemToDelete !== null) {
            const requestUrl = `${apiCalls.treatmentPlan}/${itemToDelete._id}`;
            fetchMethodRequest('DELETE', requestUrl)
                .then(response => {
                    if (response.respCode) {
                        showToasterMessage(response.respMessage, 'success');
                        setValue('treatmentPlanHeading', '');
                        setValue('note', '');
                        const txPlanModalElement = document.getElementsByClassName('TxPlanModal')[0];
                        if (txPlanModalElement) {
                            txPlanModalElement.click();
                        }
                    } else {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                })
                .catch(error => {
                    console.error('Error canceling treatment:', error);
                    showToasterMessage(response.errorMessage, 'danger');
                });
            setIsDeleteModalOpen(false);
        }
    };
    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };
    function handleEditClick(option, e) {
        e.preventDefault();
        setSelectedItemForEdit(option);
        setFormType('edit');

        setSelectedItemTxPlan(option.txPlanId ? option.txPlanId : '');
        setIsOpenFormModal(true);
        setCustomFieldValidation({ save: false });
    };

    const closeFormModal = (formType, response) => {
        if (formType == 'save') {
            fetchMethodRequest("GET", `${apiCalls.treatmentPlan}/${response.details._id}`).then(res => {
                setValue('treatmentPlanHeading', res.details);
                setValue('note', res.details.note);
            })
        }
        setIsOpenFormModal(false);
        setCustomFieldValidation({ save: true });
    }

    return <div className="drug_datatable">
        <div className="d-flex align-items-center">
            <label>Treatment Plan</label>
            <Button type="button" rounded className="drugsfield_add_icon mb-1" onClick={(event) => {
                event.preventDefault(); // Prevent default form submission
                addNewPharmacy(event); // Call the intended function
                setFormType('add');
            }}><FontAwesomeIcon
                    icon={faPlus}
                    size='lg'
                    data-toggle="tool-tip"
                    title={"Add"}
                    type="button"
                /></Button>
        </div>
        <div className="row">{getAutoCompleteField(i, item, Controller, control, getValues, setValue, errors)}</div>
        <div className="TxPlanModal">
            {isOpenFormModal &&
                <FormModal
                    // onRef={(ref) => formModalRef = ref}
                    openFormModal={isOpenFormModal}
                    allUsersData={[selectedItemForEdit]}
                    tableRowData={selectedItemForEdit || {}}
                    totalRecords={1}
                    first={1}
                    rows={1}
                    closeFormModal={closeFormModal}
                    type={'Treatment Plan Modal'}
                    customHeader={'Treatment Plan'}
                    tablefieldsToShow={[]}
                    originalTableFields={[]}
                    formType={formType}
                    // formFields={Pharmacies().getFormFields}
                    formFields={getFormFields}
                    getDataFromServer={() => { }}
                    apiUrl={apiCalls.treatmentPlan}
                    role={loginRole}
                    filterCriteria={{}}
                    menuList={[]}
                    routeTo={apiCalls.treatmentPlan}
                    displayViewOfForm={'modal'}
                    actionsTypes={'add'}
                    entityType={config.entityType}
                    defaultData={{ patientId: getValues('patientId') }}
                    dragAndDropTableData={tableData}
                    addCustomValidations={addCustomValidations}
                />
            }
        </div>
        <DeleteRowModal
            openDeleteModal={isDeleteModalOpen}
            deleteSelectedRow={handleDelete}
            closeDeleteModal={closeDeleteModal}
        />
    </div>

}