import React, { useRef, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// File Upload
import FileUpload from '../../Form/components/FileUpload';

// config file
const BulkUploadModal = (props) => {
    const [clickCount, setClickCount] = useState(0);
    const fileUploadRef = useRef();
    const clinicId = localStorage.ClinicData ? JSON.parse(localStorage.ClinicData) : {};
    const defaultData = clinicId?._id ? { clinicId: clinicId._id } : null;
    let defaultCriteria = props.defaultCriteria ? props.filterCriteria : '';
    /**@FileName Extraction */
    let filename = props.sampleFilePath.split("/").slice(-1)[0]

    const uploadToServer = () => {
        if (clickCount == 0) {
            fileUploadRef.current.UploadFileToServer();
        }
    }

    const handleClickCount = () => {
        setClickCount(0);
    }


    const { t } = props;

    return (
        <Modal isOpen={props.openBulkUploadModal}
            className={`modal-dialog-centered modal-dialog--primary modal-dialog--header `}>

            <ModalHeader className="modal__header">
                <button className="lnr lnr-cross modal__close-btn" type="button"
                    onClick={() => props.closeBulkModal() && props.reload(defaultCriteria)} />
                <p className="bold-text  modal__title"> {'Bulk Upload'} </p>
            </ModalHeader>
            <ModalBody>
                <div className='row'>
                    <div className='col-5'>
                        {'Sample Document'}
                    </div>
                    <div className='col-7'>
                        <a className='btn btn-primary outline text-white' href={props.sampleFilePath} download={filename}>Download</a>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-5'>
                        {'Please Attach File'}
                    </div>
                    <div className='col-7 bulkuploadbutton'>
                        <FileUpload type="bulkUpload"
                            defaultData={defaultData}
                            ref={fileUploadRef}
                            url={props.type}
                            close={props.closeBulkModal}
                            reload={props.reload}
                            criteria={defaultCriteria}
                        />
                    </div>
                </div>
                <div className='row mt-5'>
                    <div className='col-sm-12 text-center'>
                        <Button color='primary' outline
                            onClick={() => props.closeBulkModal() && props.reload(defaultCriteria)}>{'Close'}</Button>
                        <Button color='primary'
                            onClick={uploadToServer}>{'Upload'} - {props.type}</Button>
                    </div>
                </div>
            </ModalBody>

        </Modal >
    );

}

export default BulkUploadModal;