import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const FeeSchedules = (props) => {
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(window.location.href.includes("edit"));
  const [addSelectedRecord, setAddSelectedRecord] = useState(window.location.href.includes("create"));
  const [viewSelectedRecord, setViewSelectedRecord] = useState(window.location.href.includes("view"));

  const feesTypes = [
    { label: 'Normal', value: "normal" },
    { label: 'CoPay', value: "coPay" },
    { label: 'Out Of Network', value: "outOfNetwork" },
    { label: 'Fixed Benifit', value: "fixedBenifit" },
    { label: 'Manual Blue Book', value: "manualBlueBook" },
  ]

  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Fee Schedules");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);


  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 40,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search",
        displayInSettings: true,
      },
      {
        name: "description",
        type: "text",
        capitalizeTableText: true,
        placeholder: "Description",
        label: "Description",
        header: "Description",
        width: 300,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        filter: true,
        field: "description",
        mobile: true,
        displayInSettings: true,
        style: {
          maxWidth: '500px',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap', /* Correct property to prevent wrapping */
          textOverflow: 'ellipsis',
        },
      },

      {
        name: "type",
        type: "dropDown",
        capitalizeTableText: true,
        placeholder: "Type",
        label: "Type",
        header: "type",
        width: 300,
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "text",
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "type",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "defaultFeeSchedule",
        type: "checkbox",
        placeholder: "Default FeeSchedule",
        label: "Default FeeSchedule",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Default FeeSchedule",
        derivedValue: "defaultFeeSchedule=undefined",
        capitalizeTableText: true,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "defaultFeeSchedule",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "5",
        textAlign: "center",
        show: true,
        style: {},
        fieldType: 'dropDown',
        options: [
          { label: 'True', value: true, color: 'success' },
          { label: 'False', value: false, color: 'danger' },
          { label: 'False', value: undefined, color: 'danger' }],
        field: "defaultFeeSchedule",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 100,
        "fieldType": "Actions",
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "sortable": false,
        displayInSettings: true,


      }
    ];
    return data;
  };

  const custonActionIcon = (rowData, col) => {
    return rowData?.defaultFeeSchedule ? [] : ['edit', 'delete'];
  }

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        header: "Description",
        width: 45,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "text",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "description",
        mobile: true,
        required: true,
        displayInSettings: true,
      },

      {
        name: "type",
        type: "dropDown",
        placeholder: "Type",
        label: "Type",
        header: "type",
        width: 45,
        id: "type",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "text",
        options: feesTypes,
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        required: true,
        field: "type",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "hidden",
        type: "checkbox",
        placeholder: "Hidden",
        label: "Hidden",
        derivedValue: "hidden=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "hidden",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        show: true,
        disabled: true,
        mobile: true,
        globalSearchField: "true",
        required: false,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "useGlobalFees",
        type: "checkbox",
        placeholder: "Use Global Fees",
        label: "Use Global Fees",
        derivedValue: "useGlobalFees=undefined",
        actions: [],
        width: 110,
        actionsNumber: [],
        id: "useGlobalFees",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        show: true,
        disabled: true,
        mobile: true,
        globalSearchField: "true",
        required: false,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ]
  };

  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Name",
        derivedValue: "name=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "name",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "instructions",
        type: "text",
        placeholder: "Instructions",
        label: "Instructions",
        width: "130px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Instructions",
        derivedValue: "instructions=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "instructions",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "instructions",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "refills",
        type: "number",
        placeholder: "Refills",
        label: "Refills",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Refills",
        derivedValue: "refills=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "refills",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "refills",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "dosage",
        type: "text",
        placeholder: "Dosage",
        label: "Dosage",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Dosage",
        derivedValue: "dosage=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "dosage",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "dosage",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  const rowClassName = (item) => {
    if (item?.defaultFeeSchedule === true) {
      return 'feeSchedule_TableRow_Color'
    } else {
      return null
    }
  }

  const excludeKeysForExport = ["createdBy","__v","_id"]


  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={isEditPermission}
          sample={true}
          PrintRequired={isEditPermission}
          printRequried={true}
          actionsTypes={actionTypes}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          // sampleFilePath={filePath.Drugs}
          globalSearch={"Description / Type"}
          displayName="Fee Schedules"
          type="Fee Schedules"
          routeTo={apiCalls.feeSchedules}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.feeSchedules}
          apiUrl={apiCalls.feeSchedules}
          selectedId={params.id}
          actionDependentFunction={custonActionIcon}
          rowClassName={rowClassName}
          excludeKeysForExport={excludeKeysForExport}
        />
      }
    </span>
  );
};

export default FeeSchedules;
